export default [
  { name: "AGRIGENTO", code: "AG" },
  { name: "ALESSANDRIA", code: "AL" },
  { name: "ANCONA", code: "AN" },
  { name: "AOSTA", code: "AO" },
  { name: "AREZZO", code: "AR" },
  { name: "ASCOLI PICENO", code: "AP" },
  { name: "ASTI", code: "AT" },
  { name: "AVELLINO", code: "AV" },
  { name: "BARI", code: "BA" },
  { name: "BARLETTA-ANDRIA-TRANI", code: "BT" },
  { name: "BELLUNO", code: "BL" },
  { name: "BENEVENTO", code: "BN" },
  { name: "BERGAMO", code: "BG" },
  { name: "BIELLA", code: "BI" },
  { name: "BOLOGNA", code: "BO" },
  { name: "BOLZANO", code: "BZ" },
  { name: "BRESCIA", code: "BS" },
  { name: "BRINDISI", code: "BR" },
  { name: "CAGLIARI", code: "CA" },
  { name: "CALTANISSETTA", code: "CL" },
  { name: "CAMPOBASSO", code: "CB" },
  { name: "CARBONIA-IGLESIAS", code: "CI" },
  { name: "CASERTA", code: "CE" },
  { name: "CATANIA", code: "CT" },
  { name: "CATANZARO", code: "CZ" },
  { name: "CHIETI", code: "CH" },
  { name: "COMO", code: "CO" },
  { name: "COSENZA", code: "CS" },
  { name: "CREMONA", code: "CR" },
  { name: "CROTONE", code: "KR" },
  { name: "CUNEO", code: "CN" },
  { name: "ENNA", code: "EN" },
  { name: "FERMO", code: "FM" },
  { name: "FERRARA", code: "FE" },
  { name: "FIRENZE", code: "FI" },
  { name: "FOGGIA", code: "FG" },
  { name: "FORLI’-CESENA", code: "FC" },
  { name: "FROSINONE", code: "FR" },
  { name: "GENOVA", code: "GE" },
  { name: "GORIZIA", code: "GO" },
  { name: "GROSSETO", code: "GR" },
  { name: "IMPERIA", code: "IM" },
  { name: "ISERNIA", code: "IS" },
  { name: "LA SPEZIA", code: "SP" },
  { name: "L’AQUILA", code: "AQ" },
  { name: "LATINA", code: "LT" },
  { name: "LECCE", code: "LE" },
  { name: "LECCO", code: "LC" },
  { name: "LIVORNO", code: "LI" },
  { name: "LODI", code: "LO" },
  { name: "LUCCA", code: "LU" },
  { name: "MACERATA", code: "MC" },
  { name: "MANTOVA", code: "MN" },
  { name: "MASSA-CARRARA", code: "MS" },
  { name: "MATERA", code: "MT" },
  { name: "MEDIO CAMPIDANO", code: "VS" },
  { name: "MESSINA", code: "ME" },
  { name: "MILANO", code: "MI" },
  { name: "MODENA", code: "MO" },
  { name: "MONZA E BRIANZA", code: "MB" },
  { name: "NAPOLI", code: "NA" },
  { name: "NOVARA", code: "NO" },
  { name: "NUORO", code: "NU" },
  { name: "OGLIASTRA", code: "OG" },
  { name: "OLBIA-TEMPIO", code: "OT" },
  { name: "ORISTANO", code: "OR" },
  { name: "PADOVA", code: "PD" },
  { name: "PALERMO", code: "PA" },
  { name: "PARMA", code: "PR" },
  { name: "PAVIA", code: "PV" },
  { name: "PERUGIA", code: "PG" },
  { name: "PESARO E URBINO", code: "PU" },
  { name: "PESCARA", code: "PE" },
  { name: "PIACENZA", code: "PC" },
  { name: "PISA", code: "PI" },
  { name: "PISTOIA", code: "PT" },
  { name: "PORDENONE", code: "PN" },
  { name: "POTENZA", code: "PZ" },
  { name: "PRATO", code: "PO" },
  { name: "RAGUSA", code: "RG" },
  { name: "RAVENNA", code: "RA" },
  { name: "REGGIO CALABRIA", code: "RC" },
  { name: "REGGIO EMILIA", code: "RE" },
  { name: "RIETI", code: "RI" },
  { name: "RIMINI", code: "RN" },
  { name: "ROMA", code: "RM" },
  { name: "ROVIGO", code: "RO" },
  { name: "SALERNO", code: "SA" },
  { name: "SASSARI", code: "SS" },
  { name: "SAVONA", code: "SV" },
  { name: "SIENA", code: "SI" },
  { name: "SIRACUSA", code: "SR" },
  { name: "SONDRIO", code: "SO" },
  { name: "TARANTO", code: "TA" },
  { name: "TERAMO", code: "TE" },
  { name: "TERNI", code: "TR" },
  { name: "TORINO", code: "TO" },
  { name: "TRAPANI", code: "TP" },
  { name: "TRENTO", code: "TN" },
  { name: "TREVISO", code: "TV" },
  { name: "TRIESTE", code: "TS" },
  { name: "UDINE", code: "UD" },
  { name: "VARESE", code: "VA" },
  { name: "VENEZIA", code: "VE" },
  { name: "VERBANO-CUSIO-OSSOLA", code: "VB" },
  { name: "VERCELLI", code: "VC" },
  { name: "VERONA", code: "VR" },
  { name: "VIBO VALENTIA", code: "VV" },
  { name: "VICENZA", code: "VI" },
  { name: "VITERBO", code: "VT" },
];
